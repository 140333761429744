










































import { Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { FileDto, FileRequest, SimpleServerResponse, User } from '@/store/types'
import { Form, KeyValue, MediaFile } from '@/store/commonFormInput/types'
import {
  GET_EMPLOYEE_BASE_DATA,
  GET_TASKS,
  SET_EMPLOYEE_BASE_DATA
} from '@/store/employee/actions.type'
import { EmployeeProfile, Tasks } from '@/store/employee/types'
import SubmitSuccess from '@/components/forms/SubmitSuccess.vue'
import MediaUploader from '@/components/common/MediaUploader.vue'
import Rights from '@/common/rights'
import BaseForm from '@/components/forms/BaseForm.vue'
import InputTypes from '@/common/formTypes'
import PhotoMutation from '@/common/getPhotoMutation'
import { DELETE_PHOTO, GET_PHOTO, SAVE_PHOTO } from '@/store/user/actions.type'
import Enums from '@/common/Enums'
import { hasRight } from '@/utils/rights'
import userDomain from '@/store/common/userDomain'
import MetaComponent from '@/common/MetaComponent.vue'

  @Component({ components: { BaseForm, SubmitSuccess, MediaUploader } })
export default class Profile extends MetaComponent {
    @Action(GET_EMPLOYEE_BASE_DATA)
    public getBaseInformation: () => Promise<EmployeeProfile>

    @Action(SET_EMPLOYEE_BASE_DATA)
    public setBaseInformation: (data: EmployeeProfile) => Promise<void>

    @Action(GET_TASKS)
    public getTasks: () => Promise<Array<Tasks>>

    @Action(SAVE_PHOTO)
    public savePhoto: (data: FileRequest) => Promise<SimpleServerResponse>

    @Action(GET_PHOTO)
    public getPhoto: (role: string) => Promise<FileDto>

    @Action(DELETE_PHOTO)
    public deletePhoto: (role: string) => Promise<SimpleServerResponse>

    @Getter('userGetter') userGetter: User
    @Getter('photoFileNameGetter') persistedPhoto: string | undefined

    employeeProfile: EmployeeProfile = {
      accountId: undefined,
      city: undefined,
      description: undefined,
      email: undefined,
      fallbackMail: undefined,
      firstname: undefined,
      photo: {},
      lastname: undefined,
      mobile: undefined,
      number: undefined,
      phone: undefined,
      postalCode: undefined,
      profileId: undefined,
      salutation: undefined,
      socialMedia: undefined,
      street: undefined,
      supplement: undefined,
      tasks: [],
      tasksFrontendModel: [],
      title: undefined
    }

    allTasks: Array<Tasks> = []

    photo: MediaFile = {}
    submitSuccess = false
    photoSaved = false

    get fields (): Form[] {
      return [
        {
          id: 'description',
          key: 'description',
          type: InputTypes.TEXTAREA,
          label: this.$i18n.t('employee.profile.description').toString(),
          md: 12
        },
        {
          id: 'salutation',
          key: 'salutation',
          type: InputTypes.SELECT,
          label: this.$i18n.t('personal-information.salutation').toString(),
          options: this.salutations
        },
        {
          id: 'title',
          key: 'title',
          type: InputTypes.SELECT,
          label: this.$i18n.t('personal-information.title').toString(),
          options: this.titles
        },
        {
          id: 'firstname',
          key: 'firstname',
          type: InputTypes.TEXT,
          label: this.$i18n.t('personal-information.firstName').toString()
        },
        {
          id: 'lastname',
          key: 'lastname',
          type: InputTypes.TEXT,
          label: this.$i18n.t('personal-information.lastName').toString()
        },
        {
          id: 'photo',
          key: 'photo',
          type: InputTypes.PHOTO,
          label: this.$i18n.t('employee.profile.photo').toString()
        },
        {
          id: 'online',
          key: 'socialMedia',
          type: InputTypes.TEXT,
          label: this.$i18n.t('employee.profile.social-media').toString()
        },
        {
          id: 'tasks',
          key: 'tasksFrontendModel',
          type: InputTypes.CHECKBOXGROUP,
          label: this.$i18n.t('employee.profile.support').toString(),
          options: this.tasksCheckBoxGroupOptions,
          md: 12
        }
      ]
    }

    get tasksCheckBoxGroupOptions (): Array<KeyValue> {
      return this.allTasks.map(task => {
        return {
          text: this.$i18n.t('employee.profile.task-types.' + task.type).toString(),
          value: task.id
        } as KeyValue
      })
    }

    get writeable (): boolean {
      return hasRight(Rights.EMPLOYEE_WRITE_OWN_PROFILE)
    }

    get readonly (): boolean {
      return hasRight(Rights.EMPLOYEE_READ_OWN_PROFILE)
    }

    get salutations (): KeyValue[] {
      return Enums.SALUTATIONS.map(key => {
        return {
          value: key,
          text: this.$i18n.t('personal-information.SALUTATIONS.' + key).toString()
        } as KeyValue
      })
    }

    get titles (): KeyValue[] {
      return Enums.TITLES.map(key => {
        return {
          value: key,
          text: this.$i18n.t('personal-information.TITLES.' + key).toString()
        } as KeyValue
      })
    }

    mounted (): void {
      this.$root.$emit('load')
      this.getTasks().then((data: Array<Tasks>) => {
        this.allTasks = data
      }).finally(() => this.loadBaseInformation())
    }

    loadBaseInformation (): void {
      this.getBaseInformation().then(data => {
        this.employeeProfile = data
        this.employeeProfile.tasksFrontendModel = this.employeeProfile.tasks?.map(task => {
          return task.id
        })
        this.getPhoto(userDomain.EMPLOYEE).then(data => {
          this.$set(this.employeeProfile, 'photo', { file: data.base64Content })
        }).catch(() => {
          this.$set(this.employeeProfile, 'photo', { })
        }).finally(() => {
          this.$root.$emit('end-load')
        })
      }).finally(() => {
        this.$root.$emit('end-load')
      })
    }

    clearPhoto (): void {
      this.$set(this.employeeProfile, 'photo', { })
    }

    save (event: Event): Promise<void> {
      event.preventDefault()
      this.employeeProfile.tasks = this.allTasks.filter(task => {
        return this.employeeProfile.tasksFrontendModel?.includes(task.id)
      })
      let savedPhotoSuccessfully = false
      let savedProfileSuccessfully = false
      this.$root.$emit('load')
      return this.setBaseInformation(this.employeeProfile)
        .then(() => {
          savedPhotoSuccessfully = true
          this.photoSaved = true
        })
        .catch(() => {
          savedPhotoSuccessfully = false
          this.photoSaved = false
        }).finally(() => {
          this.callSavePhoto().then(() => {
            savedProfileSuccessfully = true
          }).catch(() => {
            savedProfileSuccessfully = false
          }).finally(() => {
            this.submitSuccess = savedPhotoSuccessfully && savedProfileSuccessfully
            this.$root.$emit('end-load')
            this.$root.$emit(
              'alert',
              this.$i18n.t(this.submitSuccess ? 'info.save-success' : 'info.save-failed', {
                what: this.$i18n.t(this.submitSuccess ? 'main-menu-hszg.profile' : savedProfileSuccessfully ? 'profile-photo' : 'main-menu-hszg.profile')
              }).toString(),
              '',
              !this.submitSuccess
            )
          })
        })
    }

    callDeletePhoto (photoMutation: string): Promise<SimpleServerResponse> {
      if (photoMutation === 'CHANGED' || photoMutation === 'DELETED') {
        return this.deletePhoto(userDomain.EMPLOYEE)
      } else {
        return Promise.resolve({ message: 'ok', requestStatus: '200' }) as Promise<SimpleServerResponse>
      }
    }

    callSavePhoto (): Promise<SimpleServerResponse> {
      const photoMutation = PhotoMutation.getPhotoMutation(this.persistedPhoto, this.employeeProfile.photo)
      return this.callDeletePhoto(photoMutation).then(() => {
        if (photoMutation === 'CHANGED' || photoMutation === 'ADDED') {
          if (this.employeeProfile.photo && this.employeeProfile.photo.base64ImageData !== undefined && this.employeeProfile.photo.fileForUpload !== undefined) {
            const file: FileDto = {
              base64Content: this.employeeProfile.photo.base64ImageData,
              filename: this.employeeProfile.photo.fileForUpload.name
            }
            const dto: FileRequest = {
              role: userDomain.EMPLOYEE,
              file: file
            }

            return this.savePhoto(dto)
          } else {
            return Promise.reject(Error('image not available'))
          }
        } else {
          return Promise.resolve({ message: 'ok', requestStatus: '200' }) as Promise<SimpleServerResponse>
        }
      }).catch((error) => {
        return Promise.reject(error) as Promise<SimpleServerResponse>
      }) as Promise<SimpleServerResponse>
    }

    closeInfo (event: Event): void {
      event.preventDefault()
    }
}
